
<template>
    <a-layout style="height:100%;">
        <a-layout-content style="height:100%;">
            <div class="yj-conten"
                 style="min-height: calc(100% - 48px); background-color:#fff;width:calc(100% - 32px);min-width:max-content">
                <a-card title="任务描述" :bordered="false">
                    <a-form-model layout="horizontal"
                                  style="width:800px;"
                                  :model="Tasks"
                                  :rules="rules"
                                  ref="ruleForm"
                                  :label-col="formItemLayout.labelCol"
                                  :wrapper-col="formItemLayout.wrapperCol">
                        <a-form-model-item label="任务名称"
                                           prop="Name">
                            <a-input placeholder="请输名称" v-model="Tasks.Name"></a-input>
                        </a-form-model-item> 
                        <a-form-model-item label="开始时间"
                                           required
                                           prop="BeginTime">
                            <a-date-picker v-model="Tasks.BeginTime"
                                           show-time
                                           type="date"
                                           :disabled-date="disabledStartDate"
                                           format="YYYY-MM-DD HH:mm:ss"
                                           placeholder="选择开始时间"
                                           style="width:100%"
                                           @openChange="handleStartOpenChange" />
                        </a-form-model-item>
                        <a-form-model-item label="结束时间"
                                           required
                                           prop="EndTime">
                            <a-date-picker show-time
                                           style="width:100%"
                                           type="date"
                                           :disabled-date="disabledEndDate"
                                           format="YYYY-MM-DD HH:mm:ss"
                                           placeholder="选择结束时间"
                                           :open="endOpen"
                                           @openChange="handleEndOpenChange"
                                           v-model="Tasks.EndTime" />
                        </a-form-model-item>
                        <a-form-model-item label="任务目标"
                                           prop="PatenerNums">
                            <a-input-number :min="1"
                                            style="width:70px"
                                            :value="Tasks.PatenerNums"
                                            @change="partnerNumsChange" />
                        </a-form-model-item>
                        <a-form-model-item label="任务佣金"
                                           prop="TaskBonus">
                            <a-input-number :min="0" placeholder type="number" style="width:70px" v-model="Tasks.TaskBonus"></a-input-number>
                        </a-form-model-item>
                        <a-form-model-item label="任务说明">
                            <Rich v-bind:value.sync="Tasks.Remark" rows="5"></Rich>
                            <!-- <a-input placeholder="请输入说明~" type="textarea" v-model="Tasks.Remark"></a-input> -->
                        </a-form-model-item>
                        <a-form-item label="排名奖励">
                            <div>
                                <a-button type="primary" value="点击添加" @click="addRanking" size="small">点击添加</a-button>
                            </div>
                            <div class="form-inline" :key="item.Rank" v-for="item in Tasks.Rewards">
                                <div v-if="!item.IsDel">

                                    名次：
                                    <a-input type="number"
                                             class="margin-r"
                                             v-model="item.Rank"
                                             style="width:100px"
                                             step="1"
                                             min="1"
                                             @change="RankChange(item)"
                                             @keyup="RankKeyUp(item)" />奖励：
                                    <a-input type="number"
                                             v-model="item.Reward"
                                             style="width:100px"
                                             step="0.1"
                                             min="0"
                                             @change="RewardChange(item)" />
                                    <a-button type="primary"
                                              style="background-color:red;margin-left:12px;border:none;"
                                              value="删除"
                                              @click="deleteCurrentRow(item)"
                                              size="small">删除</a-button>
                                </div>
                                <!--v-if="Rewards.length === $index+1"-->
                            </div>
                        </a-form-item>

                        <a-form-model-item label class="textal_c">
                            <div v-if="this.$route.query.rowType !='select'"> 
                            <a-button type="primary" @click="save('ruleForm')">保存</a-button>
                            </div>
                        </a-form-model-item>
                    </a-form-model>
                </a-card>
            </div>
        </a-layout-content>
    </a-layout>

</template>
<script type="text/javascript">
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    import Rich from "@/components/RichEditor.vue";
    export default {
        name: "TaskDetail",
        data() {
            return {
                test: undefined,
                Tasks: {
                    CustomeID: "",
                    PatenerNums: "", //发展合伙人数量
                    areadyGetCount: 0,
                    areadyCompleteCount: 0,
                    leaveTimeStr: "23小时后结束",
                    NewsID: 0,
                    H5Url: null,
                    IsShowNews: false,
                    IsShowH5: false,
                    IsShowClass: false,
                    NewsTitle: null,
                    ClassTitle: null,
                    CenterPointAddress: null,
                    TempName: null,
                    Rewards: [
                    ],
                    UserTask: null,
                    EndDay: 0,
                    ID: 0,
                    TaskType: 3,
                    BeginTime: "", //开始时间
                    EndTime: "", //结束时间
                    TaskBonus: "", //任务佣金
                    UserFlag: 0,
                    InputTime: "",
                    Remark: "", //任务备注
                    CustomerID: "",
                    Name: "发展合伙人任务", //名字
                    TaskTarget: null,
                    IsNewerTask: null,
                    ActionID: null,
                    TemplateID: null,
                    LimitMoney: null
                },

                endOpen: false,
                rules: {
                    Name: [{ message: "请输入活动名称", required: true, trigger: "blur" }],
                    BeginTime: [{ required: true, message: "请选择活动开始时间", trigger: "change" }],
                    EndTime: [{ required: true, message: "请选择活动结束时间", trigger: "change" }],
                    PatenerNums: [{ message: "任务目标不能为空且为整数", type: "integer", required: true, trigger: "blur" }],
                    TaskBonus: [{ message: "请输入任务佣金", required: true, trigger: "blur" }],
                },
                formItemLayout: {
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 3 }
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 20 }
                    }
                },
            };
        },
        beforeCreate() {

        },
        methods: {
            partnerNumsChange(value) {
                this.Tasks.PatenerNums = value;
            },
            deleteCurrentRow: function (obj) {
                var index = this.Tasks.Rewards.findIndex(item => {
                    if (item.Rank == obj.Rank) {
                        return true;
                    }
                });
                this.Tasks.Rewards.splice(index, 1);
            },
            addRanking: function () {
                var self = this;


                var currentRow = {
                    TaskID: self.Tasks.ID,
                    Rank: self.Tasks.Rewards.length + 1,
                    Reward: 0,
                    IsDel: false
                };

                self.Tasks.Rewards.push(currentRow);
            },

            loadData() {
                var self = this;
                var op = {
                    url: "/MarketingModule/Task/TaskDetail",
                    data: {
                        //ID: self.ID,
                        ID: this.$route.query.id,
                        TaskType: self.Tasks.TaskType
                    },
                    OnSuccess: function (res) {
                        self.Tasks = res.data;
                        self.Tasks.BeginTime = util.TimeStamptoDateTime(res.data.BeginTime, 'yyyy-MM-dd hh:mm:ss')

                        self.Tasks.EndTime = util.TimeStamptoDateTime(res.data.EndTime, 'yyyy-MM-dd hh:mm:ss');



                    }
                };
                http.Post(op);

            },
            save: function (formName) {
           
                var self = this;
                this.$refs[formName].validate(valid => {
                    if (valid) {

                        if (this.Tasks.ID != 0) {
                            self.edit(formName);
                        } else {
                            self.add(formName);
                        }
                    } else {
                        return false;
                    }
                });

            },
            add: function () {

                var self = this;
                var op = {
                    url: "/MarketingModule/Task/TaskPartner",
                    data: self.Tasks,
                    OnSuccess: function (data) {
                       
                        if (data.data>0) {
                            self.$message.success("发布成功");
                            setTimeout(() => {
                                self.$router.push({ path: "/TaskManageModule/Task/List" });
                            }, 1000);
                        }
                    }
                };
                http.Post(op);
            },
            edit: function () {

                var self = this;
                var op = {
                    url: "/MarketingModule/Task/EditPartner_Task",
                    data: self.Tasks,
                    OnSuccess: function (data) {
                         console.log(data)
                        if (data.data>0) {
                            self.$message.success("编辑成功");
                            setTimeout(() => {
                                self.$router.push({ path: "/TaskManageModule/Task/List" });
                            }, 1000);
                        }
                    }
                };
                http.Post(op);

            },
            RankChange(item) {
                console.log(item);
            },
            RankKeyUp(item) {
                console.log(item);
            },
            RewardChange(item) {
                console.log(item);
            },
            disabledStartDate(startValue) {
                const endValue = this.Tasks.EndTime;
                if (!startValue || !endValue) {
                    return false;
                }
                return startValue.valueOf() > endValue.valueOf();
            },
            disabledEndDate(endValue) {
                const startValue = this.Tasks.BeginTime;
                if (!endValue || !startValue) {
                    return false;
                }
                return startValue.valueOf() >= endValue.valueOf();
            },
            handleStartOpenChange(open) {
                if (!open) {
                    this.endOpen = true;
                }
            },
            handleEndOpenChange(open) {
                this.endOpen = open;
            },
        },
        components: {
            Rich
        },
        mounted() {
            if (this.$route.query.id != undefined) {
                this.loadData();
            }
        },
        watch: {
            startValue(val) {
                console.log('startValue', val);
            },
            endValue(val) {
                console.log('endValue', val);
            },
        },
    };


</script>


